<template>
  <div v-if="selectedUser">
    <div class="row">
      <div class="col-md-3 col-6">
        <b-card bg-variant="primary" class="text-white-50">
          <p class="text-white m-0">
            <i class="fas fa-users"></i> <br />Followers<br />
            <strong>
              {{ selectedUser.followers && selectedUser.followers.length }}
            </strong>
          </p>
        </b-card>
      </div>
      <div class="col-md-3 col-6">
        <b-card bg-variant="success" class="text-white-50">
          <p class="text-white m-0">
            <i class="fas fa-user-plus"></i> <br />Following<br />
            <strong>
              {{ selectedUser.following && selectedUser.following.length }}
            </strong>
          </p>
        </b-card>
      </div>
      <div class="col-md-3 col-6">
        <b-card bg-variant="info" class="text-white-50">
          <p class="text-white m-0">
            <i class="far fa-thumbs-up"></i> <br />Likes<br />
            <strong>{{
              selectedUser.likes && selectedUser.likes.length
            }}</strong>
          </p>
        </b-card>
      </div>
      <div class="col-md-3 col-6">
        <b-card bg-variant="dark" class="text-white-50">
          <p class="text-white m-0">
         <i class="fas fa-ban"></i> <br />Blocked<br />
            <strong>{{
              selectedUser.blocked_users && selectedUser.blocked_users.length
            }}</strong>
          </p>
        </b-card>
      </div>
    </div>
    <b-card no-body>
      <b-card-body class="user-details-card">
        <div class="row mb-3 mt-4">
          <div class="col-md-4">Full Name</div>
          <div class="col-md-8">
            {{ selectedUser.fullname|| "Not Provided" }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Birthday</div>
          <div class="col-md-8">
             {{ moment(selectedUser.birthday).format("LL") || "Not Provided" }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Relationship</div>
          <div class="col-md-8">
            {{ selectedUser.relationship|| "Not Provided" }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Bio</div>
          <div class="col-md-8">
            {{ selectedUser.bio || "Not Provided"}}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Location</div>
          <div class="col-md-8">
            {{ selectedUser.location|| "Not Provided" }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Nomad Type</div>
          <div class="col-md-8">
            {{ selectedUser.nomad_type|| "Not Provided" }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Nomad Level</div>
          <div class="col-md-8">
            {{ selectedUser.nomad_level|| "Not Provided" }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Challenge</div>
          <div class="col-md-8">
            {{ selectedUser.challenge || "Not Provided"}}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-4">Apple ID</div>
          <div class="col-md-8">
            {{ selectedUser.appleId || "Not Provided"}}
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-4">Google ID</div>
          <div class="col-md-8">
            {{ selectedUser.googleId || "Not Provided"}}
          </div>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
export default {
  computed: {
    selectedUser() {
      return this.$store.state.normalUser.user;
    },
  },
};
</script>

<style>
.user-details-card .col-md-4{
  font-weight: 900;
}
</style>